import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;
export const createBoot = async (data) => {
    try {
      
  
      const token = localStorage.getItem("token");
  
      const response = await axios.post(`${API_URL}/smart-bots/store`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
     
      return response; // إرجاع الرد
  
    } catch (error) {
      if (error.response) {
        console.error("Error response from API:", error.response.data);
        return error.response.data; // إرجاع الأخطاء لمعالجتها
      } else {
        console.error("Unknown error:", error.message);
        throw error;
      }
    }
  };

  export const deleteBoot = async (id) => {
    const token = localStorage.getItem("token");
  
    try {
  
      const response = await axios.delete(`${API_URL}/smart-bots/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Validate response format
      if ( response.status === 200) {
         
        return response.data; // Return the full response object
      }else if(response.status===404){
        return "لا يوجد موظفين"
      } 
      else {
        throw new Error(response.data.msg || "Failed to retrieve employees data.");
      }
    } catch (error) {
      // Handle and re-throw error for further handling in the calling component
      console.log(error);
    }
  };
  export const getBootData = async () => {
    const token = localStorage.getItem("token");
  
    try {
  
      const response = await axios.get(`${API_URL}/smart-bots/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Validate response format
      if ( response.status === 200) {
         
        return response.data; // Return the full response object
      }else if(response.status===404){
        return "لا يوجد موظفين"
      } 
      else {
        throw new Error(response.data.msg || "Failed to retrieve employees data.");
      }
    } catch (error) {
      // Handle and re-throw error for further handling in the calling component
      console.log(error);
    }
  };

  export const updateBootStatus = async (bot,botId, status) => {
    const token = localStorage.getItem("token");
    let updatedBoot={...bot,status:status}
    try {
      const response = await axios.post(`${API_URL}/smart-bots/update/${botId}`, 
        updatedBoot,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.msg || "Failed to update bot status");
      }
    } catch (error) {
      console.error("Error updating bot status:", error);
      throw error;
    }
  };
  
  export const changeStatus = async (bootId) => {
    const token = localStorage.getItem("token");
  
    try {
      const response = await axios.put(
        `${API_URL}/smart-bots/change-status/${bootId}`, 
        {}, // Empty body object
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response ;
    } catch (error) {
      console.error("Error changing status:", error);
      throw error; // Re-throw to handle in component
    }
  };