import "./Footer.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as X } from "../../assets/x.svg";
import { ReactComponent as Instagram } from "../../assets/insta.svg";
import { ReactComponent as Youtube } from "../../assets/youtube.svg";
const Footer = () => {
  let token = localStorage.getItem("token");
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3">
                <ul>
                  <li>
                    {token ? <Link to="/dashboard">مركز التحكم</Link> : <Link to="/register">إنشاء حساب جديد</Link>}
                  </li>
                  <li>
                    <Link to="/employeeLogin">الدخول كموظف</Link>
                  </li>
                  <li>
                    <Link to="/login">الدخول إلى حسابي</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <ul>
                  <li>
                    {token ? <Link to="/home">عن أد واتس</Link> : <Link to="/">عن أد واتس</Link>}
                  </li>
                  {token ? (
                    <li>
                      <Link to="/home">الرئيسية</Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/">الرئيسية</Link>
                    </li>
                  )}
                </ul>
              </div>

              <div className="col-md-3">
                <ul>
                  <li>
                    {token ? <Link to="/home">باقات أد واتس</Link> : <Link to="/">باقات أد واتس</Link>}
                  </li>
                  <li>
                    {token ? <Link to="/home">الأسئلة الشائعة</Link> : <Link to="/">الأسئلة الشائعة</Link>}
                  </li>
                  <li>
                    {token ? <Link to="/home">اتصل بنا</Link> : <Link to="/">اتصل بنا</Link>}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-3 text-center d-flex flex-column align-items-center justify-content-between">
            <div className="footer-logo">Logo</div>
            <div className="social-icons">
              <ul>
                <li>
                  <X />
                </li>

                <li>
                  <Instagram />
                </li>
                <li>
                  <Youtube />
                </li>

                <li>
                  <Facebook />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
