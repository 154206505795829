import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { ReactComponent as Auto } from "../../assets/robot-ico.svg";
import { useEffect, useState } from "react";
import { changeStatus, deleteBoot, getBootData } from "../../services/bootservice";
import { toast } from "react-toastify";
import "./smartBot.scss";
import axios from "axios";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SmartBot = () => {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [runEffect,setRunEffect]=useState(0)
  useEffect(() => {
    fetchBots();
  }, [runEffect]);

  const fetchBots = async () => {
    try {
      const response = await getBootData();
      if (response) {
        setBots(response);
      }
    } catch (error) {
      console.error("Error fetching bots:", error);
      toast.error("حدث خطأ في تحميل الروبوتات");
    } finally {
      setLoading(false);
    }
  };
  const handelChangeStatus= async(id)=>{
    try{
      let response=await changeStatus(id)
      if(response.status===200){
        toast.success("تم تغيير حالة الروبوت بنجاح")
        setRunEffect((...prev)=>prev+1)
      }
    }catch(error){
      toast.error("حدث خطأ أثناء تغيير حالة الروبوت")
    }
  }
  const handelDelete= async(id)=>{
    try{
      let response=await deleteBoot(id)
        toast.success("تم حذف الروبوت بنجاح")
        setRunEffect((...prev)=>prev+1)
      
    }catch(error){  
      console.log(error);
      toast.error("حدث خطأ أثناء حذف الروبوت")
    }

  }
  const renderExistingBot = (bot) => (
    <div key={bot.id} className="bot-card">
      <div className="bot-header">
        <div className="bot-status d-flex justify-content-between w-100">
          <button onClick={()=>handelDelete(bot.id)} className="delete-btn p-1" >
           <FontAwesomeIcon icon={faTrash} />
          </button>
          <span className={`status-badge ${bot.status === "on" ? "active" : "inactive"}`}>
            {bot.status === "on" ? "مفعل" : "معطل"}
          </span>
        </div>
        
      </div>

      <div className="bot-info">
        <div className="bot-name">
          <Auto className="bot-icon" />
          <span>{bot.keyword || "start"}</span>
        </div>
        <div className="whatsapp-number">
          
          <span>{bot.whatsapp_number}</span>
        </div>
      </div>

      <div className="bot-actions">
     
        <button onClick={()=>handelChangeStatus(bot.id)} className="action-btn">
       
          <span> {bot.status === "off" ? "تشغيل" : "إيقاف"}</span>
        </button>
      </div>
    </div>
  );

  const renderCreateBot = () => (
    <Link to="/smart-bot/create" className="create-bot-card">
      <Auto className="create-icon" />
      <span>إنشاء روبوت جديد</span>
    </Link>
  );

  return (
    <Layout>
      <div className="container mt-4">
        <div className="smart-bot-header">
          <h2>الروبوت الذكي</h2>
          <p className="description">
            الروبوت الذي يتفاعل مع عملائك وسيستجيب لهم ويساعدهم!
          </p>
        </div>

        {loading ? (
          <div className="loading-spinner">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">جاري التحميل...</span>
            </div>
          </div>
        ) : (
          <div className="bots-grid">
            {renderCreateBot()}
            {bots.map(bot => renderExistingBot(bot))}
          </div>
        )}

        <div className="pagination">
          <span>{bots.length} سجل تم إيجاده</span>
          <div className="pagination-controls">
            <button className="nav-btn" disabled>←</button>
            <span>1</span>
            <button className="nav-btn">→</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SmartBot;
