import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export const fetchAutoReplies = async () => {
  const token = localStorage.getItem("token");

  try {

    const response = await axios.get(`${API_URL}/auto-replies/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    // Validate response format
    if ( response.status === 200) {
       
      return response.data// Return the full response object
    }else if(response.status===404){
      return "لا يوجد ردود آلية"
    } 
    else {
      throw new Error(response.data.msg || "Failed to retrieve auto-replies data.");
    }
  } catch (error) {
    // Handle and re-throw error for further handling in the calling component
    console.log(error);
  }
};
export const changeStatus = async (ruleId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_URL}/auto-replies/change-status/${ruleId}`, 
      {}, // Empty body object
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing status:", error);
    throw error; // Re-throw to handle in component
  }
};

export const createAutoReply = async ( ruleData) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_URL}/auto-replies/store`,
      ruleData,
      {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to create auto-reply");
    }
  } catch (err) {
    console.error("Error saving auto-reply rule:", err);
    throw err;
  }
};

export const deleteAutoReply = async (ruleId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(`${API_URL}/auto-replies/delete/${ruleId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    console.error("Error deleting auto-reply rule:", error);
    throw error;
  }
}
